<template>
   <section class="content-container">
      <SectionTabs :tabs="tab_component"/>
      <router-view/>
   </section>
</template>
<script>
import SectionTabs from "@/components/Section/SectionTabs.vue";
export default {
   components: {
      SectionTabs,
   },
   data() {
      return {
         title: "Noticias",
         tab_component: [{
            name: "Noticias",
            route: "news-list",
         }, {
            name: "Crear",
            route: "news-add",
         }, {
            name: "Categorías",
            route: "news-categories"
         }],
      };
   },
};
</script>